import React, { useEffect, useState } from "react";
import { CancelToken } from "axios";
import PropTypes from "prop-types";

import Page from "../../components/Page";
import Grid from "../../components/Grid";
import ListTag from "../../components/ListTag";
import Toolbar from "../../components/Toolbar";

import api from "../../api";
import collaborationTypes from "../../data/collaborationTypes.json";
import timezones from "../../data/timezones.json";

import "./Person.sass";

export default function Person({ history, match }) {
  const id = match.params.personId;

  const [person, setPerson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const source = CancelToken.source();
    api
      .get(`/users/${id}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        const { data } = response;
        setPerson(data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          history.push("/login");
        } else {
          console.error("An error occurred: ", err);
          setError(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return function unmount() {
      source.cancel("Route unmounted.");
    };
  }, [id]);

  if (loading) {
    // TODO better loading state
    return null;
  }

  if (error) {
    return "Something went wrong";
  }

  const ideaList = [];
  person.ideas.forEach((idea) => {
    ideaList.push(
      <ListTag
        key={idea.id}
        id={idea.id}
        type="ideas"
        label={idea.title}
        descriptor={idea.creator ? "Creator" : ""}
      />
    );
  });

  // Collect disciplines
  const disciplines = [];
  if (person.discipline_1 !== "none") disciplines.push(person.discipline_1);
  if (person.discipline_2 !== "none") disciplines.push(person.discipline_2);

  // Get pretty label for collaboration
  let collaborationLabel = "Loading";
  const collaborationIndex = collaborationTypes
    .map((type) => type.id)
    .indexOf(person.collaboration);
  if (collaborationIndex >= 0) {
    collaborationLabel = collaborationTypes[collaborationIndex].label;
  }

  // Get pretty label for timezone
  let timezoneLabel = "Loading";
  const timezoneIndex = timezones
    .map((zone) => zone.name)
    .indexOf(person.timezone);
  if (timezoneIndex >= 0) {
    const { name, description } = timezones[timezoneIndex];
    timezoneLabel = `${description} (${name})`;
  }

  // Compile list of Idea interests
  const interestsList = [];
  if (person.interests) {
    const tags = person.interests.split(";");
    if (tags.length > 0) {
      tags.forEach((tag) => {
        if (tag.length) {
          interestsList.push(<li key={tag}>{tag.trim()}</li>);
        }
      });
    }
  }

  // Compile list of classes
  const classList = [];
  if (person.classes) {
    person.classes.forEach((entry) => {
      const entityName = `${entry.name} `;
      const entityRange = `(${entry.start}–${entry.end})`;
      if (entry.name) {
        classList.push(
          <li key={Math.random().toString()}>
            <p>
              {entityName}
              <span className="faded">{entityRange}</span>
            </p>
          </li>
        );
      }
    });
  }

  const renderClasses =
    person.classes &&
    person.classes.length >= 1 &&
    person.classes[0].name !== "";

  // List of social media profiles
  const socialMedia = [];
  if (person.twitter && person.twitter.length > 0) {
    socialMedia.push(
      <li key="twitter">
        <a href={person.twitter} target="_blank" rel="noreferrer">
          Twitter
        </a>
      </li>
    );
  }
  if (person.facebook && person.facebook.length > 0) {
    socialMedia.push(
      <li key="facebook">
        <a href={person.facebook} target="_blank" rel="noreferrer">
          Facebook
        </a>
      </li>
    );
  }
  if (person.linkedin && person.linkedin.length > 0) {
    socialMedia.push(
      <li key="linkedin">
        <a href={person.linkedin} target="_blank" rel="noreferrer">
          LinkedIn
        </a>
      </li>
    );
  }
  if (person.instagram && person.instagram.length > 0) {
    socialMedia.push(
      <li key="instagram">
        <a href={person.instagram} target="_blank" rel="noreferrer">
          Instagram
        </a>
      </li>
    );
  }

  // Profile image
  const defaultImage = "none";
  let image = defaultImage;
  if (person.photo) {
    image = `url(${API_URL}${person.photo[0]})`;
  }

  return (
    <Page>
      <Grid className="profile">
        <header>
          <div className="profile-avatar">
            <div className="profile-photo" style={{ backgroundImage: image }} />
            <div className="profile-description">
              <h1>{person.name}</h1>
              <p>{`${person.position} at ${person.institution}`}</p>
            </div>
          </div>
          <Toolbar person={person} />
        </header>

        <section className="grid-area-main">
          <div className="metadata">
            <h3>Bio</h3>
            <p>{person.bio}</p>
          </div>

          {person.goals.length > 0 && (
            <div className="metadata">
              <h3>Collaboration Goals</h3>
              <ul className="person-goals">
                {person.goals.map((goal) => (
                  <li key={goal.id}>{goal.label}</li>
                ))}
              </ul>
            </div>
          )}

          {person.interests.length > 0 && (
            <div className="metadata">
              <h3>Interest Tags</h3>
              <ul className="languages">{interestsList}</ul>
            </div>
          )}

          {person.languages && person.languages.length > 0 && (
            <div className="metadata">
              <h3>
                Preferred{" "}
                {person.languages.length === 1 ? "Language" : "Languages"}
              </h3>
              <ul className="languages">
                {person.languages.map((language) => (
                  <li key={language}>{language}</li>
                ))}
              </ul>
            </div>
          )}

          {person.communication && (
            <div className="metadata">
              <h3>Communication Preferences</h3>
              <ul className="communicationMethods">
                {person.communication.map((type) => (
                  <li key={type}>{type}</li>
                ))}
              </ul>
            </div>
          )}

          <div className="metadata">
            <h3>{disciplines.length === 1 ? "Discipline" : "Disciplines"}</h3>
            <ul className="disciplines">
              {disciplines.map(
                (discipline) =>
                  discipline && <li key={discipline}>{discipline}</li>
              )}
            </ul>
          </div>

          <div className="metadata">
            <h3>Desired Collaboration Type</h3>
            <p>{collaborationLabel}</p>
          </div>

          <div className="metadata">
            <h3>Time Zone</h3>
            <p>{timezoneLabel}</p>
          </div>

          {person.website.length > 0 && (
            <div className="metadata">
              <h3>Website</h3>
              <a href={person.website} target="_blank" rel="noreferrer">
                {person.website}
              </a>
            </div>
          )}

          {socialMedia && socialMedia.lenght > 0 && (
            <div className="metadata">
              <h3>Social Media</h3>
              <ul className="social-media">{socialMedia}</ul>
            </div>
          )}

          {renderClasses && (
            <div className="metadata">
              <h3>{person.classes.length === 1 ? "Course" : "Courses"}</h3>
              <ul className="classes">{classList}</ul>
            </div>
          )}
        </section>

        <section className="grid-area-sidebar">
          {person.ideas.length > 0 && (
            <div className="metadata">
              <h3>Ideas</h3>
              <ul className="person-ideas">{ideaList}</ul>
            </div>
          )}
        </section>
      </Grid>
    </Page>
  );
}

Person.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      personId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
